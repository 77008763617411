export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const Bxh = () => import('../../components/Bxh.vue' /* webpackChunkName: "components/bxh" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilter = () => import('../../components/CategoryFilter.vue' /* webpackChunkName: "components/category-filter" */).then(c => wrapFunctional(c.default || c))
export const CategoryNoData = () => import('../../components/CategoryNoData.vue' /* webpackChunkName: "components/category-no-data" */).then(c => wrapFunctional(c.default || c))
export const DIalogXXSuccess = () => import('../../components/DIalogXXSuccess.vue' /* webpackChunkName: "components/d-ialog-x-x-success" */).then(c => wrapFunctional(c.default || c))
export const DesignFormDialog = () => import('../../components/DesignFormDialog.vue' /* webpackChunkName: "components/design-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const DownloadDialog = () => import('../../components/DownloadDialog.vue' /* webpackChunkName: "components/download-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExportDialog = () => import('../../components/ExportDialog.vue' /* webpackChunkName: "components/export-dialog" */).then(c => wrapFunctional(c.default || c))
export const FormDialog = () => import('../../components/FormDialog.vue' /* webpackChunkName: "components/form-dialog" */).then(c => wrapFunctional(c.default || c))
export const ImageUpload = () => import('../../components/ImageUpload.vue' /* webpackChunkName: "components/image-upload" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PcArticle = () => import('../../components/PcArticle.vue' /* webpackChunkName: "components/pc-article" */).then(c => wrapFunctional(c.default || c))
export const PcFooter = () => import('../../components/PcFooter.vue' /* webpackChunkName: "components/pc-footer" */).then(c => wrapFunctional(c.default || c))
export const PcGlobalForm = () => import('../../components/PcGlobalForm.vue' /* webpackChunkName: "components/pc-global-form" */).then(c => wrapFunctional(c.default || c))
export const PcHeader = () => import('../../components/PcHeader.vue' /* webpackChunkName: "components/pc-header" */).then(c => wrapFunctional(c.default || c))
export const PcHeaderLite = () => import('../../components/PcHeaderLite.vue' /* webpackChunkName: "components/pc-header-lite" */).then(c => wrapFunctional(c.default || c))
export const PcSwitch = () => import('../../components/PcSwitch.vue' /* webpackChunkName: "components/pc-switch" */).then(c => wrapFunctional(c.default || c))
export const PcTitle = () => import('../../components/PcTitle.vue' /* webpackChunkName: "components/pc-title" */).then(c => wrapFunctional(c.default || c))
export const PdfHtml = () => import('../../components/PdfHtml.vue' /* webpackChunkName: "components/pdf-html" */).then(c => wrapFunctional(c.default || c))
export const PriceToImg = () => import('../../components/PriceToImg.vue' /* webpackChunkName: "components/price-to-img" */).then(c => wrapFunctional(c.default || c))
export const Project = () => import('../../components/Project.vue' /* webpackChunkName: "components/project" */).then(c => wrapFunctional(c.default || c))
export const ProjectDialog = () => import('../../components/ProjectDialog.vue' /* webpackChunkName: "components/project-dialog" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordMail = () => import('../../components/ResetPasswordMail.vue' /* webpackChunkName: "components/reset-password-mail" */).then(c => wrapFunctional(c.default || c))
export const RowCard = () => import('../../components/RowCard.vue' /* webpackChunkName: "components/row-card" */).then(c => wrapFunctional(c.default || c))
export const SubNav = () => import('../../components/SubNav.vue' /* webpackChunkName: "components/sub-nav" */).then(c => wrapFunctional(c.default || c))
export const Subscription = () => import('../../components/Subscription.vue' /* webpackChunkName: "components/subscription" */).then(c => wrapFunctional(c.default || c))
export const TabNav = () => import('../../components/TabNav.vue' /* webpackChunkName: "components/tab-nav" */).then(c => wrapFunctional(c.default || c))
export const Zoom = () => import('../../components/Zoom.vue' /* webpackChunkName: "components/zoom" */).then(c => wrapFunctional(c.default || c))
export const AddAddressDialog = () => import('../../components/addAddressDialog.vue' /* webpackChunkName: "components/add-address-dialog" */).then(c => wrapFunctional(c.default || c))
export const BookCard = () => import('../../components/bookCard.vue' /* webpackChunkName: "components/book-card" */).then(c => wrapFunctional(c.default || c))
export const ImageList = () => import('../../components/imageList.vue' /* webpackChunkName: "components/image-list" */).then(c => wrapFunctional(c.default || c))
export const LoginDialog = () => import('../../components/loginDialog.vue' /* webpackChunkName: "components/login-dialog" */).then(c => wrapFunctional(c.default || c))
export const ResetPasswordDialog = () => import('../../components/resetPasswordDialog.vue' /* webpackChunkName: "components/reset-password-dialog" */).then(c => wrapFunctional(c.default || c))
export const TinymceDynamicLoadScript = () => import('../../components/Tinymce/dynamicLoadScript.js' /* webpackChunkName: "components/tinymce-dynamic-load-script" */).then(c => wrapFunctional(c.default || c))
export const Tinymce = () => import('../../components/Tinymce/index.vue' /* webpackChunkName: "components/tinymce" */).then(c => wrapFunctional(c.default || c))
export const TinymcePlugins = () => import('../../components/Tinymce/plugins.js' /* webpackChunkName: "components/tinymce-plugins" */).then(c => wrapFunctional(c.default || c))
export const TinymceToolbar = () => import('../../components/Tinymce/toolbar.js' /* webpackChunkName: "components/tinymce-toolbar" */).then(c => wrapFunctional(c.default || c))
export const CustomElSliderButton = () => import('../../components/custom-el-slider/button.vue' /* webpackChunkName: "components/custom-el-slider-button" */).then(c => wrapFunctional(c.default || c))
export const CustomElSlider = () => import('../../components/custom-el-slider/index.vue' /* webpackChunkName: "components/custom-el-slider" */).then(c => wrapFunctional(c.default || c))
export const CustomElSliderMarker = () => import('../../components/custom-el-slider/marker.vue' /* webpackChunkName: "components/custom-el-slider-marker" */).then(c => wrapFunctional(c.default || c))
export const ProductLeft = () => import('../../components/product/ProductLeft.vue' /* webpackChunkName: "components/product-left" */).then(c => wrapFunctional(c.default || c))
export const ProductRight = () => import('../../components/product/ProductRight.vue' /* webpackChunkName: "components/product-right" */).then(c => wrapFunctional(c.default || c))
export const ProductQuote = () => import('../../components/product/Quote.vue' /* webpackChunkName: "components/product-quote" */).then(c => wrapFunctional(c.default || c))
export const ProductBuilderDialogSelectAddr = () => import('../../components/product-builder/DialogSelectAddr.vue' /* webpackChunkName: "components/product-builder-dialog-select-addr" */).then(c => wrapFunctional(c.default || c))
export const ProductBuilderStep1 = () => import('../../components/product-builder/step-1.vue' /* webpackChunkName: "components/product-builder-step1" */).then(c => wrapFunctional(c.default || c))
export const ProductBuilderStep2 = () => import('../../components/product-builder/step-2.vue' /* webpackChunkName: "components/product-builder-step2" */).then(c => wrapFunctional(c.default || c))
export const ProductBuilderStep3 = () => import('../../components/product-builder/step-3.vue' /* webpackChunkName: "components/product-builder-step3" */).then(c => wrapFunctional(c.default || c))
export const ProductBuilderStepMixin = () => import('../../components/product-builder/stepMixin.vue' /* webpackChunkName: "components/product-builder-step-mixin" */).then(c => wrapFunctional(c.default || c))
export const TableChangeTable = () => import('../../components/table/ChangeTable.vue' /* webpackChunkName: "components/table-change-table" */).then(c => wrapFunctional(c.default || c))
export const TableMailTable = () => import('../../components/table/MailTable.vue' /* webpackChunkName: "components/table-mail-table" */).then(c => wrapFunctional(c.default || c))
export const TableNewPriceTable = () => import('../../components/table/NewPriceTable.vue' /* webpackChunkName: "components/table-new-price-table" */).then(c => wrapFunctional(c.default || c))
export const TableStockTable = () => import('../../components/table/StockTable.vue' /* webpackChunkName: "components/table-stock-table" */).then(c => wrapFunctional(c.default || c))
export const TableTotalTable = () => import('../../components/table/TotalTable.vue' /* webpackChunkName: "components/table-total-table" */).then(c => wrapFunctional(c.default || c))
export const TableUnitTable = () => import('../../components/table/UnitTable.vue' /* webpackChunkName: "components/table-unit-table" */).then(c => wrapFunctional(c.default || c))
export const TableParamTable = () => import('../../components/table/paramTable.vue' /* webpackChunkName: "components/table-param-table" */).then(c => wrapFunctional(c.default || c))
export const TinymceComponentsEditorImage = () => import('../../components/Tinymce/components/EditorImage.vue' /* webpackChunkName: "components/tinymce-components-editor-image" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
